import React from "react"
import Ipad from "../components/Ipad.js"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const loading = ({ location }) => {
  setTimeout(() => {
    navigate(location.state.page, { state: { source: location.state.source } })
  }, 2000)
  return (
    <Ipad data={{ bgColor: "var(--azul)" }}>
      <Container>
        <StaticImage src="../images/clessy.png" alt="clessy" className="img" />
        <p>BUSCANDO LA MEJOR Y MÁS SOSTENIBLE EXPERIENCIA PARA TI...</p>
      </Container>
    </Ipad>
  )
}
const Container = styled.section`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  .img {
    width: 100px;
  }
  p {
    margin-top: 40px;
    width: 100%;
    font-family: GEB;
    color: var(--blu);
    font-size: 1.5rem;
  }
`

export default loading
